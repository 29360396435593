<template>
  <div class="aboutbox">
    <div class="headerbox">
    </div>
    <div class="center">
      <div class="titletext">
        About
        <div class="titleicon"></div>
      </div>
      <div class="titletext">
        Us
      </div>
      <div class="infobox">
        <div class="text">
          Chengdu Orecto Import and Export Trading Co.,Ltd(hereafter referred to as Orecto) is an enterprise specialized in excellent quality of PVC flooring.
           Our factory has been in the line for more than 34 years and has successfully
          stepped into international market with its best quality and innovative.
          Having accumulated much experience in these fields, we have gained good reputation for years of excellent quality, service and professional advice on
          customers requests.
           <div class="_" />
         Meanwhile,We have established business relationships with many floor tile distributors and franchisers all over the world building up an impressive worldwide sales network.
          <div class="_" /> We strive to ensure your satisfaction
          for each and every job, from start to finish, you can be assured of a great experience.
          If you have any business requirements please feel free to contact us directly with your details.
          We look forward to building rewarding
          partnerships with companies worldwide.
        </div>
        <img class="imgbox" src="../../assets/images/all/pc-about-info.png" alt="">
        <div class="bgbox"></div>
      </div>
      <div class="msgbox">
        <div class="msgtext">
          <div class="msg-title">Our Vision</div>
          <div class="msg-content">To be your priority of choice for PVC flooring service with the most trustworthy,eco-friendly and affordable flooring</div>
        </div>
        <div class="msgtext1">
          <div class="msg-title">Our Mission</div>
          <div class="msg-content">To provide the products with the most safe and guaranteed quality that requires less time for maintenance and more time for family happiness</div>
        </div>
      </div>
      <div class="iconbox">
        <div class="iconitem" v-for="item in icon" :key="item.id">
          <img class="iconimg" :src="item.msg" alt="">
          <p>{{item.title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: [
        {
          id: 1,
          msg: require('../../assets/images/all/pc-about-icon1.png'),
          title: 'Harmonious'
        },
        {
          id: 2,
          msg: require('../../assets/images/all/pc-about-icon2.png'),
          title: 'Comfortable'
        },
        {
          id: 3,
          msg: require('../../assets/images/all/pc-about-icon3.png'),
          title: 'Eco-friendly'
        },
        {
          id: 4,
          msg: require('../../assets/images/all/pc-about-icon4.png'),
          title: 'Love and dedicated'
        },
      ]
    };
  },
}
</script>

<style lang="scss" scoped>
.aboutbox {
  overflow: hidden;
  .headerbox {
    width: 19.2rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/images/all/pc-about-title.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.6rem;
    .centertext {
      width: 6.97rem;
      height: 1.65rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.6rem;
      font-family: Paytone One, Paytone One;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.9);
      position: relative;
      .line {
        width: 3rem;
        height: 0.02rem;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
      .line2 {
        width: 3rem;
        height: 0.02rem;
        background: #fff;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  .center {
    width: 13rem;
    margin: 0 auto;
    .titletext {
      font-size: 0.5rem;
      font-family: "PoetsenOne", Tahoma, Geneva, Verdana, sans-serif;
      color: #000000;
      font-weight: 800;
      position: relative;
      padding-left: 0.2rem;
    }
    .titleicon {
      width: 0.4rem;
      height: 0.15rem;
      background: #a1b3ad;
      position: absolute;
      top: 0.06rem;
      left: 0;
      z-index: -1;
    }
    .infobox {
      width: 100%;
      margin-top: 0.6rem;
      margin-bottom: 1.1rem;
      display: flex;
      justify-content: space-between;
      position: relative;
      .text {
        width: 6.3rem;
        font-size: 0.22rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #000000;
        ._ {
          height: 0.3rem;
        }
      }
      .imgbox {
        width: 5.3rem;
        height: 6.3rem;
      }
      .bgbox {
        width: 13rem;
        height: 7.3rem;
        position: absolute;
        left: 4rem;
        top: 0.2rem;
        z-index: -1;
        background: url("../../assets/images/all/pc-about-bg.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .msgbox {
      width: 100%;
      height: 3.43rem;
      background: url("../../assets/images/all/pc-about-msgbg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      margin-bottom: 1.5rem;
      .msgtext {
        width: 5.07rem;
        height: 1.5rem;
        text-align: center;
        position: absolute;
        top: 0.4rem;
        left: 0.99rem;
        .msg-title {
          font-size: 0.28rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 0.33rem;
        }
        .msg-content {
          margin-top: 0.16rem;
          font-size: 0.22rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 0.26rem;
        }
      }
      .msgtext1 {
        width: 5.07rem;
        height: 1.5rem;
        text-align: center;
        position: absolute;
        bottom: 0.4rem;
        right: 0.99rem;
        .msg-title {
          font-size: 0.28rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 0.33rem;
        }
        .msg-content {
          margin-top: 0.16rem;
          font-size: 0.22rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 0.26rem;
        }
      }
    }
    .iconbox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      .iconitem {
        text-align: center;
        .iconimg {
          width: 1.2rem;
          height: 1.2rem;
          margin: 0 auto;
        }
        p {
          width: 100%;
          font-size: 0.28rem;
          font-weight: 600;
          color: #000000;
          line-height:0.33rem;
        }
      }
    }
  }
}
</style>